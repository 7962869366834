function indexToDay(index){
if(index === 0){
return "Sunday"
}
if(index === 1){
return "Monday"
}
if(index === 2){
return "Tuesday"
}
if(index === 3){
return "Wednesday"
}
if(index === 4){
return "Thursday"
}
if(index === 5){
return "Friday"
}
if(index === 6){
return "Saturday"
}
}

export default indexToDay;