const Navbar = () => { 
    return ( <nav className="navbar">
        

<div class="topnav" id="myTopnav">
  <a href='/' class="active">Home</a>
  <a href='/'>Search By Date</a>
  <a href='/QuickSearch'>Quick Search</a>
  <a href='/FinalGradeCalculator'>Grade Calculator</a>
  
           
 {/* <a href='/SearchByRoom'>Search By Room</a> */}
  
</div>
</nav>

  


    );
}
export default Navbar;
