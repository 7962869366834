
import { useEffect, useState } from "react";
import getRooms from './/util/getRooms.js'

const SearchByRoom= () => {
  



  return <main>
      <h1 className = "title">Search By Room...</h1>
      <h1 className = "title">... Is Coming Soon</h1>

      

     
  </main>
  
}

  export default SearchByRoom;