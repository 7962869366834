import './App.css';
import Home from './Home'
import Navbar from './Navbar'

function App() {

  return (
    <div className="App">
      
      
      <div className="content">
        <Navbar />
        <Home />
      </div>
    </div>
   
  );
}

export default App;



